.contact {
    min-height: 500px;
}
.contact > .row {
    margin-bottom: 7px;
    border-bottom: solid lightgrey 1px;
}
.contact .label {
    font-weight: bold;
}

.contact .value {
}
.contact .value-long {
    font-size: 15px;
}
