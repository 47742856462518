.title img {
    width: 100%;
}

.title h1 {
    margin-top: -70px;
    color: white;
    font-weight: bold;
    font-size: 51px;

    text-shadow: 1px 0 gray, -1px 0 gray, 0 1px gray, 0 -1px gray,
    1px 1px gray, -1px -1px gray, 1px -1px gray, -1px 1px gray;
}

.mobile .title h1 {
    font-size: 20px;
    margin-top: -30px;
}
