.App {
    font-size: 20px;
}

.App h1 {
    font-weight: bold;
}

#nav-label {
    margin-left: 10px;
}

.page-section {
    padding-top: 65px;
    border-bottom: solid lightgrey 1px;
}

.space-small {
    margin-top: 5px;
}
